.portfolio-image {
	border-radius: 1rem;
	overflow: hidden;
	img {
		width: 100%;
		max-height: 440px;
		//min-height: 400px;
		object-fit: cover;
		object-position: top left;
	}
	@include media-breakpoint-down(lg){
		img{
			min-height: 100%;
		}
	}
	@include media-breakpoint-down(sm){
		img{
			min-height: 100%;
		}
	}
}
.portfolio-info {
	.portfolio-catgory {
		color: $grey-100;
	}
	.portfolio-title {
		color: $grey-50;
		font-size: $text-3xl;
		margin-bottom: 2rem;
	}
	.portfolio-text {
		color: $grey-50;
		font-size: $text-lg;
		font-weight: 400;
	}
	.portfolio-tech {
		color: $grey-100;
	}
	.tech-logo {
		width: 40px;
		opacity: .25;
		img {
			width: 100%;
			height: 100%;
		}
	}
	@include media-breakpoint-down(sm){
		.tech-logo {
			max-width: 50px;
		}
	}
}
.portfolio-tabs {
	ul {
		gap: 1rem;
		li {
			a {
				color: $grey-200;
				display: flex;

				&:hover {
					color: $white;
				}
			}
		}
	}
}
.portfolio-item {
	border-radius: 2rem;
	.portfolio-title {
		color: $grey-50;
		font-size: $text-xl;
		margin-bottom: 1rem;
	}
	.portfolio-text {
		color: $grey-100;
		font-size: $text-md;
		font-weight: 400;
	}
}
.portfolio-meta {
	ul {
		margin: 1rem 0;
		padding: 0;
		li {
			border: 1px dashed $light;
			padding: 0.5rem 1rem;
			border-radius: 99em;
			margin: 0 0.5rem 0.5rem 0;
			display: inline-flex;
			align-items: center;
			font-size: $text-sm;
			letter-spacing: 0.15rem;
		}
	}
}