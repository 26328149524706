@mixin font-face(
	$font-name,
	$font-path,
	$font-weight: 400,
	$font-style: normal,
	$support-required: "modern"
) {
	@font-face {
		font-family: $font-name;
		font-style: $font-style;
		font-weight: $font-weight;

		@if $support-required== "oldie" {
			src: url("#{$font-path}.eot");
			src: url("#{$font-path}.eot?#iefix") format("embedded-opentype"),
				url("#{$font-path}.woff2") format("woff2"),
				url("#{$font-path}.woff") format("woff"),
				url("#{$font-path}.ttf") format("truetype"),
				url("#{$font-path}.svg") format("svg");
		}

		@if $support-required== "recent" {
			src: url("#{$font-path}.woff2") format("woff2"),
				url("#{$font-path}.woff") format("woff"),
				url("#{$font-path}.ttf") format("truetype");
		}

		@if $support-required== "modern" {
			src: url("#{$font-path}.woff2") format("woff2"),
				url("#{$font-path}.woff") format("woff");
		}
	}

	@if $support-required !=
		"oldie" and
		$support-required !=
		"recent" and
		$support-required !=
		"modern"
	{
		@error 'Invalid support-required value. Must be "oldie"==>IE8 and above/ Android native browser 4+/ Safari 4.3 OR "recent"==>IE9+/ Android native browser 4.0+/ Safari 4.3+ OR "modern"==>IE9+/ Android native browser 4.4+/ Safari 5.1+';
	}
}

@include font-face("Syne", "../fonts/Syne-Regular", 400, normal, "oldie");
@include font-face("Syne", "../fonts/Syne-Medium", 500, normal, "oldie");
@include font-face("Syne", "../fonts/Syne-SemiBold", 600, normal, "oldie");
@include font-face("Syne", "../fonts/Syne-Bold", 800, normal, "oldie");
@include font-face("Syne", "../fonts/Syne-ExtraBold", 900, normal, "oldie");
@include font-face("icomoon", "../fonts/icomoon", 800, normal, "oldie");

[class^="wm-"],
[class*=" wm-"] {
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.wm-twitter:before {
	content: "\e900";
}
.la.la-twitter:before,
.lab.la-twitter:before,
.la-twitter:before {
	font-family: "icomoon" !important;
	content: "\e900";
}
