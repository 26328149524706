.about-wrapper {
	//background: url(#{$imgBackground}about-bg.png) no-repeat 0 0 / cover;
	position: relative;
	// &:after {
	// 	content: "";
	// 	position: absolute;
	// 	left: 0;
	// 	bottom: 0;
	// 	width: 100%;
	// 	height: 12%;
	// 	background: $white;
	// 	z-index: 1;
	// }
}

.img-effect-01 {
	position: relative;
	border-radius: 1rem;
	z-index: 2;
	img {
		border-radius: 1rem;
	}
	&:after {
		content: "";
		background-color: $primary;
		height: 50%;
		width: 50%;
		position: absolute;
		left: 0;
		bottom: 0;
		filter: blur(100px);
		border-radius: 50%;
		z-index: -1;
		opacity: 0.25;
	}
	.img-meta-top {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-radius: 50%;
		height: 200px;
		width: 200px;
		text-align: center;
		@include flex-item(column, center, center);
		border: 1px solid $gray-600;
		color: $white;
		overflow: hidden;
		animation: pulse 2s linear infinite alternate;
		&::after {
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 50%;
			z-index: -1;
			background-color: $primary;
			transform: scale(0) translate(-80%, -110%);
			@include transition(0.5);
		}
		&:hover {
			border-color: $primary;
			&::after {
				transform: scale(1) translate(0, 0);
			}
		}
	}
	.img-meta-bottom {
		@extend .img-meta-top;
		top: auto;
		left: auto;
		bottom: -1.5rem;
		right: 1.5rem;
		h5 {
			color: $white;
		}
		p {
			margin-bottom: 0;
		}

		@include media-breakpoint-down(lg) {
			right: 0;
		}
	}
}
// .cta-wrapper {
// 	position: relative;
// 	z-index: 2;
// 	.cta-module {
// 		border-radius: 2rem;
// 		position: relative;
// 		background-color: $primary;
// 		z-index: 2;
// 		overflow: hidden;
// 		&:after {
// 			content: "";
// 			position: absolute;
// 			left: 0;
// 			top: 0;
// 			width: 100%;
// 			height: 100%;
// 			background: url(../images/background/cta-bg.png) no-repeat 0 0 / cover;
// 			z-index: -1;
// 		}
// 		@include media-breakpoint-down(md) {
// 			.btn + .btn {
// 				margin-top: 0.5rem;
// 			}
// 		}
// 	}
// }
