.accordionDark {
	background-color: transparent;
	border: 0;
	border-radius: 0;
	.accordion-item {
		border-radius: 0;
		background-color: transparent;
		border: 0;
		.accordion-button {
			border-radius: 0;
			background-color: transparent;
			padding-left: 0;
			font-size: $text-2xl;
			color: $grey-100;
			border-bottom: 1px solid $grey-300;
			box-shadow: none;
			&:after {
				border-radius: 50%;
				height: 50px;
				width: 50px;
				border: 1px solid $grey-300;
				background: url('data:image/svg+xml,<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.9248 1C13.9248 4.63719 17.1281 8.07506 20.9999 8.07506M20.9999 8.07506C17.1281 8.07506 13.9248 11.5129 13.9248 15.1501M20.9999 8.07506L1 8.07507" stroke="%239C9C9C" stroke-width="1.5" stroke-linecap="square"/></svg>')
					no-repeat center center;
				transform: scaleX(1) rotate(-45deg);
				@include transition(0.25);
			}
			.accordion-num {
				display: inline-block;
				margin-right: 2rem;
			}
			&.collapsed {
				border-radius: 0 !important;
				&:after {
					transform: scaleX(1) rotate(0deg);
				}
			}
			&:hover {
				&:after {
					transform: scaleX(1) rotate(-45deg);
				}
			}
		}
	}
	.accordion-body {
		border: 0;
		color: $grey-100;
	}
	.accordion-collapse {
		border: 0;
	}
}
