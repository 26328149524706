#header {
	@include media-breakpoint-up(lg) {
		position: relative;
		.navigation-wrapper {
			position: static !important;
		}
	}
}
#mainNavigation {
	@include media-breakpoint-up(lg) {
		position: static !important;
		.dropdown {
			position: static !important;
		}
	}
}
.mega-menu {
	background-color: $black;
	//background-color: #111;
	// background-image: linear-gradient(
	// 	32deg,
	// 	rgba(8, 8, 8, 0.74) 30px,
	// 	transparent
	// );
	// background-size: 200px 200px;
	// background-position: 5px 5px;
	width: 100%;
	top: 100%;
	left: 0;
	height: 100vh;
	@include media-breakpoint-down(lg) {
		div[class*="container"] {
			padding: 0;
		}
	}
	.left-menu-head {
		border-right: 1px solid $gray-900;
		height: 100%;
	}
	padding: 2rem 0;
	.left-title-list {
		margin: 0;
		padding: 0;
		//border-right: 1px solid $gray-900;
		li {
			list-style: none;
			margin-bottom: 0.25rem;
			a {
				color: $gray-600;
				font-size: $text-lg;
				display: block;
				padding: 0.65rem 0;
				position: relative;
				overflow: hidden;
				@include transition(0.25);
				&:after {
					content: "";
					position: absolute;
					right: 0;
					bottom: 0;
					width: 0;
					height: 5px;
					background-color: $gray-800;
					//transform: translateY(-50%);
					@include transition(0.5);
				}
				&:hover,
				&.active {
					color: $white;
					&:after {
						width: 50%;
						//right: 0;
					}
				}
			}
		}
	}
	.mega-menu-lists-head {
		color: $gray-400;
		font-weight: 600;
		font-size: $text-sm;
		//max-width: 250px;
		letter-spacing: 0.05rem;
		text-transform: uppercase;
	}
	.menu-section-para {
		color: $gray-600;
		font-weight: 400;
		font-size: $text-md;
	}
	.menu-section-head {
		color: $gray-900;
		font-weight: 900;
		font-size: $text-xl;
	}

	.swiper-pagination {
		position: absolute;
		top: 0 !important;
		right: 0;
		width: 100% !important;
		left: 0 !important;
		bottom: auto !important;
		height: auto;
		margin: 0;
		border-bottom: 1px solid $gray-800;
		@include flex-item(row, space-between, flex-start);
		.swiper-pagination-bullet {
			color: $gray-600;
			width: auto;
			font-size: $text-md;
			font-weight: 800;
			background-color: transparent;
			border-radius: 0;
			height: auto;
			opacity: 1;
			position: relative;
			perspective: 1000px;
			overflow: hidden;
			margin: 0 0 -1px;
			padding-bottom: 0.5rem;
			//border-bottom: 1px solid transparent;
			flex: 1;
			&:after {
				content: "";
				position: absolute;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 1px;
				background-color: $gray-800;
				transform: translateX(-100%);
				@include transition(0.5);
			}
			&.swiper-pagination-bullet-active {
				color: $primary;
				//border-bottom: 1px solid #246bfd;
				&:after {
					background-color: $primary;
					transform: translateX(0);
				}
				&:hover {
					&:after {
						background-color: $primary;
					}
				}
			}
			.char {
				display: inline-block;
				backface-visibility: hidden;
				transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
				transition-delay: calc(var(--char-index) * 0.01s);
				text-shadow: 0 1.65em 0 $white;
			}
			&:hover {
				&:after {
					background-color: $white;
					transform: translateX(0);
					right: auto;
					left: 0;
				}
				.char {
					transform: translateY(-1.5em);
				}
			}
		}
	}
}
.mega-menu-lists {
	padding: 0.5rem 0;
	li {
		list-style: none;
		margin-bottom: 0;
		a {
			color: $gray-600;
			font-size: $text-base;
			display: inline-block;
			padding: 0 0 0.25rem;
			position: relative;
			overflow: hidden;
			@include transition(0.25);
			&:after {
				content: "";
				position: absolute;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 1px;
				background-color: $white;
				opacity: 0;
				@include transition(0.25);
				transform: translateX(-100%);
			}
			&:hover,
			&.active {
				color: $gray-300;
				&:after {
					opacity: 1;
					transform: translateX(0);
				}
			}
		}
	}
}
svg.overlay {
	grid-area: 1/1/2/2;
	position: relative;
}
.swiperMenu-item {
	display: none;
}
