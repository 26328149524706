.pageheader {
	position: relative;
	z-index: 2;
	background-color: #111111;
	background-image: linear-gradient(
		32deg,
		rgba(8, 8, 8, 0.74) 30px,
		transparent
	);
	background-size: 200px 200px;
	background-position: 5px 5px;
	overflow: hidden;
	z-index: 2;
	.scene {
		position: absolute;
		left: 0;
		top: -20%;
		z-index: -1;
		right: 0;
		.pageheader-layer-text {
			font-size: clamp(5rem, calc(-1.3252rem + 9.0226vw), 10rem) !important;
			font-weight: 900;
			text-transform: uppercase;
			background: linear-gradient(
				to bottom,
				rgba(52, 58, 64, 0) 0%,
				rgba(33, 37, 41, 0) 100%
			);
			-webkit-background-clip: text;
			-webkit-text-fill-color: rgba(0, 0, 0, 0);
			-webkit-text-stroke: 1px rgba(108, 117, 125, 0.6);
			opacity: 0.5;
		}
	}
}

.pageheader-text {
	position: relative;
	z-index: 2;
	.pageheader-title {
		font-weight: 700;
		color: $white;
		font-family: $font-fancy;
		transition: all 0.25s ease-in-out 0s;
		overflow: hidden;
	}
	p {
		color: $dark;
		font-size: $text-md;
	}
}

.serviceCircle {
	height: 15px;
	width: 15px;
	border-radius: 50%;
	background-color: $white;
}
.service-logo {
	path,
	.path {
		fill: none; /* Ensure paths are not filled */
		stroke: #fff; /* Set the stroke color to white */
		stroke-width: 2; /* Adjust the stroke width as needed */
	}
}