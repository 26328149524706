body{
	background-color: $grey-900;
}
.btn {
	text-transform: uppercase;
	@include transition(0.25);
	display: inline-flex;
	align-items: center;
	i {
		margin-left: 0.5rem;
		font-size: 125% !important;
		line-height: 1;
	}
}
@each $color, $value in $theme-colors {
	.btn-#{$color} {
		position: relative;
		z-index: 2;
		overflow: hidden;
		.arrow-icon {
			svg,
			path {
				fill: currentColor;
				margin-left: 0.5rem;
			}
		}
		&:before {
			content: "";
			position: absolute;
			-webkit-transition-duration: 800ms;
			transition-duration: 800ms;
			width: 200%;
			height: 200%;
			top: 110%;
			left: 50%;
			background: shade-color($value, 40%);
			transform: translateX(-50%);
			border-radius: 50%;
			z-index: -1;
		}
		&:hover {
			.arrow-icon {
				svg,
				path {
					fill: currentColor;
				}
			}
			&:before {
				top: -200%;
				opacity: 0.25;
			}
		}
	}
	.btn-outline-#{$color} {
		.arrow-icon {
			svg,
			path {
				fill: currentColor;
				margin-left: 0.5rem;
			}
		}
		&:hover {
			background-color: $value;
			.arrow-icon {
				svg,
				path {
					fill: currentColor;
				}
			}
		}
	}
}
.title {
	.title-text {
		font-family: $font-fancy;
		font-size: clamp(1.5rem, calc(2vw + 1.5rem), 3.125rem);
		transition: background-position 0.8s cubic-bezier(0.25, 0.1, 0.14, 0.91);
		color: $grey-200 !important;
		/*@include media-breakpoint-up(md){
			background-image: linear-gradient(-45deg, $grey-200 35%, $grey-50 65%);
			background-clip: text;
			-webkit-background-clip: text;
			-moz-background-clip: text;
			text-fill-color: transparent;
			-moz-text-fill-color: transparent;
			-webkit-text-fill-color: transparent;
		}
		@supports not (-webkit-text-fill-color: transparent) {
			color: $grey-200; !* Fallback for unsupported browsers *!
		}
		@include media-breakpoint-down(md){
			color: $white !important;
			// background-clip: text;
			// -webkit-background-clip: text;
			// -moz-background-clip: text;
			// text-fill-color: transparent;
			// -moz-text-fill-color: transparent;
			// -webkit-text-fill-color: transparent;
		}*/
	}
}
.title-animation {
	overflow: hidden;
}
.section-badge {
	text-align: center;
	font-weight: 600;
	margin-bottom: 1rem;
	font-size: 8rem;
	white-space: nowrap;
	.section-badge-text {
		font-weight: 900;
		padding: 0 5rem;
		background: linear-gradient(
										to bottom,
										rgba($gray-800, 0) 0%,
										rgba($gray-900, 0) 100%
		);
		background-clip: border-box;
		background-clip: text;
		-webkit-background-clip: text;
		-moz-background-clip: text;
		text-fill-color: transparent;
		-moz-text-fill-color: transparent;
		-webkit-text-fill-color: transparent;
		text-transform: uppercase;
		-webkit-text-stroke: 1px rgba($gray-600, 0.6);
	}
	@include media-breakpoint-down(md) {
		font-size: 4rem;
	}
}
.list-style-checked {
	li {
		position: relative;
		padding-left: 3.25rem;
		color: $dark;
		font-weight: 500;
		&:before {
			content: "\f560";
			position: absolute;
			left: 1rem;
			top: 0;
			font-family: "Line Awesome Free";
			font-weight: 900;
			font-size: 1.5rem;
			line-height: 2rem;
		}
	}
}
#scroll,
.scrollTop {
	position: fixed;
	bottom: 60px;
	right: 20px;
	cursor: pointer;
	z-index: 10;
	text-align: center;
	opacity: 1;
	@include transition(0.25);
	background-color: $secondary;
	color: $white;
	padding: 0.5rem 0;
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
	animation: upDown 4s linear 0s infinite;
}
#scroll:hover,
.scrollTop:hover {
	background-color: shade($primary, 40%);
}
@keyframes upDown {
	0% {
		transform: translateY(0%);
	}
	25% {
		transform: translateY(-25%);
	}
	50% {
		transform: translateY(0%);
	}
	75% {
		transform: translateY(25%);
	}
	100% {
		transform: translateY(0%);
	}
}

@keyframes marquee {
	0% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
	100% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}

$newColors: (
	"primary": $primary,
	"secondary": $secondary,
	"orange": $orange,
	"warning": $warning,
	"danger": $danger,
	"info": $info,
	"cyan": $cyan,
	"pink": $pink,
	"success": $success,
	"purple": $purple,
	"teal": $teal,
	"white": $white,
);
@each $name, $color in $newColors {
	.text-#{$name} {
		a {
			color: $color;
			&:hover {
				color: shade($color, 10%);
			}
			svg {
				fill: currentColor;
			}
		}
	}
	a.text-#{$name} {
		svg {
			fill: currentColor;
			path {
				fill: currentColor;
			}
		}
		&:hover {
			color: darken($color, 10%);
		}
	}
}
@each $name, $color in $newColors {
	.link-fancy-#{$name} {
		display: inline-block;
		position: relative;
		z-index: 2;
		color: $color;
		&:before {
			content: "";
			position: absolute;
			z-index: -1;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 10%;
			background-color: $color;
			transform: scale3d(0, 1, 1);
			transform-origin: 0% 50%;
			transition: transform 0.5s;
			transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		}
		&:hover {
			color: $color;
			&:before {
				transform: scale3d(1, 1, 1);
			}
		}
	}
}

@each $name, $color in $newColors {
	.bg-gradient-#{$name} {
		background: linear-gradient(
			180deg,
			rgba($color, 0.15) 0.2%,
			rgba(205, 255, 245, 0.05) 100%
		);
	}
}
$darkColors: (
	"grey-50": $grey-50,
	"grey-100": $grey-100,
	"grey-200": $grey-200,
	"grey-300": $grey-300,
	"grey-400": $grey-400,
	"grey-500": $grey-500,
	"grey-600": $grey-600,
	"grey-700": $grey-700,
	"grey-800": $grey-800,
	"grey-900": $grey-900,
);
@each $name, $color in $darkColors {
	.bg-gradient-#{$name} {
		background: linear-gradient(
			180deg,
			rgba($color, 0.95) 0%,
			rgba($color, 1) 100%
		);
	}
	.text-#{$name} {
		a {
			color: $color;
			&:hover {
				color: shade($color, 10%);
			}
			svg {
				fill: currentColor;
			}
		}
	}
}
.row-vr-devider {
	> [class*="col-"]:not(:first-child) {
		position: relative;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0.5rem;
			background-color: $gray-600;
			width: 1px;
			height: 100%;
		}
		@include media-breakpoint-down(md) {
			padding-top: 1rem;
			margin-top: 1rem;
			&:after {
				// content: "";
				// position: absolute;
				// top: 0;
				// bottom: 0;
				// left: 0.5rem;
				// background-color: $gray-600;
				height: 1px;
				width: 50%;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}
.ripple-effect {
	position: relative;
	z-index: -1;
	left: -25%;
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 1px;
		height: 1px;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		box-shadow: 0 0 0 0 darken($primary, 15%);
		animation: ripple 5s infinite;
	}
	&:before {
		animation-delay: 0.9s;
	}
	&:after {
		animation-delay: 0.6s;
	}
}
@-webkit-keyframes ripple {
	70% {
		box-shadow: 0 0 0 70px rgba($warning, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba($warning, 0);
	}
}

@keyframes ripple {
	70% {
		box-shadow: 0 0 0 70px rgba($warning, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba($warning, 0);
	}
}
.list-check {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		position: relative;
		padding-left: 2rem;
		margin-bottom: 0.75rem;
		font-size: $text-md;
		&:before {
			content: "\f00c";
			font-family: "Line Awesome Free";
			font-weight: 900;
			position: absolute;
			left: 0;
			top: 0;
		}
		a {
			color: $grey-50;
		}
	}
}
.link-glow {
	display: inline-block;
	position: relative;
	z-index: 2;
	color: $white;
	&:before {
		content: "";
		background-color: $info;
		height: 20px;
		width: 100px;
		position: absolute;
		left: -10px;
		bottom: 0;
		filter: blur(10px);
		border-radius: 50%;
		z-index: -1;
		opacity: 0;
		@include transition(0.25);
	}
	&:hover {
		color: $white;
		&::before {
			opacity: 1;
		}
	}
}

.link-arrow {
	position: relative;
	display: inline-block;
	color: $white;
	padding-right: 2rem;
	&:before {
		content: "";
		position: absolute;
		right: 0.25rem;
		top: 50%;
		transform: translateY(-50%);
		height: 24px;
		width: 24px;
		background: url('data:image/svg+xml,<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.9248 1C13.9248 4.63719 17.1281 8.07506 20.9999 8.07506M20.9999 8.07506C17.1281 8.07506 13.9248 11.5129 13.9248 15.1501M20.9999 8.07506L1 8.07507" stroke="%23ffffff" stroke-width="1.5" stroke-linecap="square"/></svg>')
			no-repeat center center;
		@include transition(0.25);
	}
	&.text-primary {
		&:before {
			background: url('data:image/svg+xml,<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.9248 1C13.9248 4.63719 17.1281 8.07506 20.9999 8.07506M20.9999 8.07506C17.1281 8.07506 13.9248 11.5129 13.9248 15.1501M20.9999 8.07506L1 8.07507" stroke="%23246bfd" stroke-width="1.5" stroke-linecap="square"/></svg>')
				no-repeat center center;
		}
	}

	&:hover {
		color: $white;
		&::before {
			opacity: 1;
		}
	}
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
	max-width: 2520px;
	padding: 0 clamp(1.25rem, calc(-0.061rem + 5.594vw), 8.75rem);
	@include media-breakpoint-up(xxl) {
		max-width: 2520px;
		padding: 0 clamp(1.25rem, calc(-0.061rem + 5.594vw), 8.75rem);
	}
}

.scene {
	position: absolute;
}
/* Recommended styles for Splitting */

.splitting {
	.word {
		display: inline-block;
		--word-percent: calc(var(--word-index) / var(--word-total));
		--line-percent: calc(var(--line-index) / var(--line-total));
	}
	--word-center: calc((var(--word-total) - 1) / 2);
	--char-center: calc((var(--char-total) - 1) / 2);
	--line-center: calc((var(--line-total) - 1) / 2);
	.char {
		display: inline-block;
		position: relative;
		--char-percent: calc(var(--char-index) / var(--char-total));
		--char-offset: calc(var(--char-index) - var(--char-center));
		--distance: calc(
			(var(--char-offset) * var(--char-offset)) / var(--char-center)
		);
		--distance-sine: calc(var(--char-offset) / var(--char-center));
		--distance-percent: calc((var(--distance) / var(--char-center)));
		&:before,
		&:after {
			content: attr(data-char);
			position: absolute;
			top: 0;
			left: 0;
			visibility: hidden;
			transition: inherit;
			user-select: none;
		}
	}
}

.whitespace {
	width: 5px;
	display: inline-block;
}

