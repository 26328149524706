.supports-wrapper {
	position: relative;
	z-index: 2;
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: -1;
		opacity: 0.025;
		background: url("#{$imgBackground}glob-bg.png") no-repeat center center /
			cover;
	}
}
.card-supports-form {
	border: 1px solid $grey-500;
}
.supports-from {
	.supports-from-title {
		color: $grey-100;
		font-weight: 400;
		font-size: $text-2xl;
	}
}
.branch-location-wrapper {
	.branch-location-title {
		color: $grey-100;
		font-weight: 400;
		font-size: $text-2xl;
	}
	.branch-location {
		@include media-breakpoint-up(lg) {
			margin-bottom: 3rem;
		}
		.country-flag {
			margin-bottom: 1rem;
		}
		.branch-head {
			color: $white;
			font-size: $text-md;
			letter-spacing: 0.05rem;
		}
		.branch-address {
			color: $grey-100;
		}
	}
}
.social-networks {
	@include flex-item(row, start, center);
	a {
		background-color: $grey-400;
		color: $dark;
		border-radius: 0.25rem;
		display: inline-block;
		width: 50px !important;
		height: 50px !important;
		border-radius: 50%;
		text-align: center;
		font-size: $text-md;
		text-decoration: none;
		transition: all 0.25s ease-in-out 0s;
		@include flex-item(column, center, center);
		i {
			color: $grey-900;
		}
		svg {
			width: 18px;
			fill: $dark;
		}
		&:hover {
			background-color: $primary;
			color: $white;
			i {
				color: $white;
			}
			svg {
				fill: $white;
			}
		}
	}
}
