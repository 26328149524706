.testimonial-img {
	img {
		max-width: 100%;
	}
}
.testimonial-content {
	.testimonial-author {
		color: $grey-100;
		font-size: $text-xl;
		font-weight: 500;
		margin-bottom: 0;
	}
	.author-designation {
		color: $grey-300;
		font-size: $text-md;
	}
	.testimonial-text {
		color: $grey-100;
		font-size: $text-md;
	}
}
.testimonialSlider {
	.swiper-slide {
		background-color: $grey-700;
		padding: 10px;
		margin: 2rem 0;
		@include transition(1);
		filter: blur(1.5px);
	}
	.swiper-slide-active {
		transform: scale(1.25);
		opacity: 1;
		z-index: 10;
		filter: blur(0);
		border: 1px solid $grey-600;
		box-shadow: 0 30px 30px -40px rgba($primary, 0.25);
	}
	@include media-breakpoint-down(xl) {
		.swiper-slide-active {
			transform: scale(1);
		}
	}
	@include media-breakpoint-down(md) {
		.swiper-slide-active {
			transform: scale(1);
			text-align: center;
		}
		.testimonial-img {
			@include flex-item(row, center, center);
			margin-bottom: 1rem;
		}
	}
}