.custom-file-upload-warp {
	border: 1px dashed $primary;
	position: relative;
	padding: .5rem;

	input[type="file"] {
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
		top: 0;
		bottom: 0;
		cursor: pointer;
	}
}
