.feature-card {
	height: 100%;
	@include transition(0.5);
	border-radius: 1rem;
	.icon-box {
		i,
		div {
			@include transition(0.5);
			transform: rotateY(0deg);
		}
	}
	&:hover {
		transform: translateY(-1rem);
		.icon-box {
			i,
			> div {
				transform: rotateY(360deg);
			}
		}
	}

	&.feature-card-01 {
		.feature-head {
			@include flex-item(row, start, center);
			margin-bottom: 0.5rem;
			.icon-box {
				margin-bottom: 0.5rem;
				margin-right: 1rem;
			}
		}
	}
	&.feature-card-02 {
		.feature-head {
			@include flex-item(column, start, start);
			margin-bottom: 0.5rem;
		}
	}
	&.feature-card-03 {
		text-align: center;
		.feature-head {
			@include flex-item(column, center, center);
			margin-bottom: 0.5rem;
		}
	}
	&.feature-card-dark {
		text-align: left;
		background-color: $grey-700;
		border: 1px solid transparent;
		.feature-title {
			color: $white;
			position: relative;
			margin-bottom: 2rem;
			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: -20px;
				width: 100%;
				height: 1px;
				background: $grey-200;
			}
			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: -20px;
				width: 15px;
				height: 1px;
				background: $grey-700;
				left: -100%;
			}
		}
		p {
			color: $body-color;
			@include transition(0.25);
		}
		.feature-head {
			@include flex-item(column, start, start);
			margin-bottom: 0.5rem;
		}
		.icon-box {
			background-color: transparent;
			padding: 0;
			width: auto;
			color: $white;
			margin-bottom: 2rem;
			svg {
				fill: none;
			}
		}
		&:after {
			content: "";
			background-color: $primary;
			height: 50%;
			width: 50%;
			position: absolute;
			left: 0;
			bottom: 0;
			filter: blur(100px);
			border-radius: 50%;
			z-index: -1;
			opacity: 0;
			@include transition(0.5);
		}
		&:hover {
			transform: translateY(-1rem);
			border-color: $grey-50;
			p {
				color: $white;
			}
			&:after {
				opacity: 0.5;
			}
			.feature-title {
				&::after {
					animation: separatorAnimation 1s infinite ease-in-out;
				}
			}
		}
	}
}

@keyframes separatorAnimation {
	0% {
		left: -100%;
	}
	100% {
		left: 100%;
	}
}
.card-dark {
	background-color: darken($gray-800, 15%);
}
