.list-circle {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		list-style: none;
		position: relative;
		padding-left: 1.5rem;
		margin-bottom: 0.5rem;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 10px;
			background-color: $secondary;
			height: 8px;
			width: 8px;
			border-radius: 50%;
		}
		a {
			text-decoration: none;
			color: $body-color;
			&:hover {
				color: $secondary;
			}
		}
	}
}

$colors: (
	"primary": $primary,
	"secondary": $secondary,
	"orange": $orange,
	"warning": $warning,
	"danger": $danger,
	"info": $info,
	"cyan": $cyan,
	"gray": $gray-500,
);
@each $name, $color in $colors {
	.list-#{$name} {
		li {
			&:before {
				background-color: $color;
				color: $color;
			}
		}
	}
}
