.navbar-brand {
	max-width: 200px;
}

.navbar-toggler {
	.navbar-toggler-icon {
		position: relative;
		height: 3px;
		width: 28px;
		border-radius: 5rem;
		background-image: none;
		background-color: $primary;
		@include transition(0.35);
		&:before,
		&:after {
			content: "";
			width: 100%;
			height: 3px;
			border-radius: 5rem;
			background-color: $primary;
			position: absolute;
			left: 0;
			top: -8px;
			transform-origin: right;
			@include transition(0.35);
		}

		&:after {
			top: 8px;
		}
	}

	&[aria-expanded="true"] {
		.navbar-toggler-icon {
			background: none;

			&:before {
				transform: rotate(-45deg);
			}

			&:after {
				transform: rotate(45deg);
				top: 12px;
			}
		}
	}
}

.navigation-wrapper {
	background: transparent;
}
#mainNavigation {
	position: relative;
	.navbar-nav {
		.nav-item {
			position: relative;
			padding: 0.5rem 1rem;
			.nav-link {
				color: $white;
				padding: 0;
				position: relative;
				overflow: hidden;
				font-size: $text-md;
				font-weight: 500;
				&.active{
					color: $primary
				}
				@include transition(0.25);
				@include flex-item(row, start, center);
				@include media-breakpoint-down(md) {
					color: $white;
					// &:before,
					// &:after {
					// 	content: attr(title);
					// 	position: absolute;
					// 	left: 0;
					// 	top: 0;
					// 	width: 100%;
					// 	height: 30px;
					// 	color: $dark;
					// 	transition: all 0.5s ease-in-out 0s;
					// }
					// &:after {
					// 	top: 30px;
					// }
					&:hover {
						background-color: transparent;
						color: $primary;

						// .dropdown-arrow {
						// 	color: $primary;
						// }
						// &:before {
						// 	top: -30px;
						// 	color: $info;
						// }

						// &:after {
						// 	top: 0px;
						// 	color: $primary;
						// }
					}

					&[data-toggle="dropdown"]:after {
						content: "\f107";
						font-family: "Line Awesome Free";
						font-weight: 900;
						// position: absolute;
						// right: 3px;
						border: 0;
					}
				}
			}

			.dropdown-menu {
				padding: 0;
				@include transition(0.25);
				box-shadow: 0 1rem 1rem rgba($dark, 0.15);
				.dropdown-item {
					padding: 0;
					.nav-link {
						color: $dark;
						padding: 0.5rem 1.5rem !important;
						position: relative;
						&:hover {
							background-color: $secondary;
							color: $white;
						}
					}
				}
				@include media-breakpoint-up(lg) {
					display: block;
					margin-top: -2px;
					border: 0;
					opacity: 0;
					visibility: hidden;
					border-radius: 0 0 0.25rem 0.25rem;
				}
			}

			&:hover {
				/* .nav-link {
           background-color: $white;
           color: $dark;
        }
        */

				.dropdown-menu {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}
.header-fixed {
	.navigation-wrapper {
		padding: 0;
	}
}
@include media-breakpoint-down(lg) {
	#mainNavigation.collapse:not(.show),
	#mainNavigation.navbar-collapse {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 300;
		height: 100vh;
		overflow: auto;
		background-color: $black;
		box-shadow: 0 5px 15px 20px rgba($primary, 0.15);
		width: 80%;
		max-width: 260px;
		padding: 1rem;
		display: block;
		visibility: hidden;
		transform: translate(-100%, 0);
		-webkit-transform: translate(-100%, 0);
		@include transition(0.35);
	}

	#mainNavigation.show {
		transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		visibility: visible;
	}
}
