.banner-wrapper {
	position: relative;
	z-index: 2;
	background: $grey-900;
	// &:before {
	// 	content: "";
	// 	background-color: $white;
	// 	position: absolute;
	// 	right: 0;
	// 	bottom: 0;
	// 	z-index: -1;
	// 	height: 10%;
	// 	width: 100%;
	// }
}
.banner-title {
	font-size: clamp(2.5rem, calc(-7.1504rem + 18.0451vw), 3rem);
}
.banner-wrapper img {
	// max-height: 460px;
	// min-height: 460px;
	object-fit: cover;
}
.banner-caption {
	width: 100%;
	z-index: 10;
	.banner-text {
		position: relative;
		z-index: 2;
		.banner-title {
			font-weight: 700;
			font-size: $text-4xl;
			color: $white;
			font-family: $font-fancy;
			transition: all 0.25s ease-in-out 0s;
			overflow: hidden;
		}
		.banner-subtitle {
			font-weight: 500;
			font-size: $text-xl;
			color: $white;
			transition: all 0.25s ease-in-out 0s;
			overflow: hidden;
		}
	}
}
.heroSlider-nav {
	.banner-button-prev,
	.banner-button-next {
		background-color: transparent;
		color: $white;
		border: 1px solid $grey-300;
		&:after {
			border: 0;
		}
		&:hover {
			background-color: $white;
			color: $dark;
			&:after {
				background-color: transparent;
			}
		}
	}
	.banner-button-prev {
		left: 5%;
		right: auto;
	}
	.banner-button-next {
		left: auto;
		right: 5%;
	}
}
