.icon-box {
	@include flex-item(column, center, center);
	border-radius: 0.3125rem;
	background-color: tint($orange, 55%);
	margin-bottom: 1rem;
	padding: 1rem;
	font-size: 1.5rem;
	&.icon-box-circle {
		border-radius: 50%;
	}
}

$icons: "xs", "sm", "md", "lg", "xl";

@for $i from 1 through length($icons) {
	$icon: nth($icons, $i);
	.icon-box-#{$icon} {
		width: 20px * $i;
		height: 20px * $i;
		padding: 0.25rem * $i;
		i {
			//font-size: calc(1rem / 1.75) * $i;
			font-size: calc(#{$i * (1rem / 1.75)});
		}
		div {
			width: 10px * $i;
			height: 10px * $i;
			@include flex-item(column, center, center);
		}
	}
}

@function tint($color, $percentage) {
	@return mix(#fff, $color, $percentage);
}
@function shade($color, $percentage) {
	@return mix(#000, $color, $percentage);
}
$newColors: (
	"primary": $primary,
	"secondary": $secondary,
	"orange": $orange,
	"warning": $warning,
	"danger": $danger,
	"info": $info,
	"cyan": $cyan,
	"pink": $pink,
	"success": $success,
	"purple": $purple,
	"teal": $teal,
	"white": $white,
);
@each $name, $color in $newColors {
	.icon-#{$name} {
		@if $color == "light" {
			background-color: tint($color, 100%);
			color: shade($dark, 100%);
			svg {
				fill: shade($color, 80%);
			}
		} @else {
			background-color: tint($color, 85%);
			color: shade($color, 55%);
			svg {
				fill: shade($color, 100%);
			}
		}
	}
}

// AVATAR
.avatar {
	@include flex-item(column, center, center);
	border-radius: 0.3125rem;
	background-color: tint($orange, 55%);
	margin-bottom: 1rem;
	overflow: hidden;
	&.avatar-circle {
		border-radius: 50%;
	}
}
@for $i from 1 through length($icons) {
	$icon: nth($icons, $i);
	.avatar-#{$icon} {
		width: 20px * $i;
		height: 20px * $i;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
