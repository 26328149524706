@import "variables";
@import "mixins";
@import "~bootstrap/scss/functions";

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

@import "~bootstrap/scss/mixins/breakpoints";
@mixin transition($transition) {
	transition: all #{$transition}s ease-in-out 0s;
}
@function tint($color, $percentage) {
	@return mix(#fff, $color, $percentage);
}
@function shade($color, $percentage) {
	@return mix(#000, $color, $percentage);
}
/* a11y */
.swiper .swiper-notification {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	opacity: 0;
	z-index: -1000;
}
.swiper-cards {
	overflow: visible;
	.swiper-slide {
		transform-origin: center bottom;
		backface-visibility: hidden;
		overflow: hidden;
	}
}
.swiper-coverflow {
}
.swiper-creative {
	.swiper-slide {
		backface-visibility: hidden;
		overflow: hidden;
		transition-property: transform, opacity, height;
	}
}
.swiper-cube {
	overflow: visible;
	.swiper-slide {
		pointer-events: none;
		backface-visibility: hidden;
		z-index: 1;
		visibility: hidden;
		transform-origin: 0 0;
		width: 100%;
		height: 100%;
		.swiper-slide {
			pointer-events: none;
		}
	}
	&.swiper-rtl .swiper-slide {
		transform-origin: 100% 0;
	}
	.swiper-slide-active {
		&,
		& .swiper-slide-active {
			pointer-events: auto;
		}
	}
	.swiper-slide-active,
	.swiper-slide-next,
	.swiper-slide-prev {
		pointer-events: auto;
		visibility: visible;
	}

	.swiper-cube-shadow {
		position: absolute;
		left: 0;
		bottom: 0px;
		width: 100%;
		height: 100%;
		opacity: 0.6;
		z-index: 0;

		&:before {
			content: "";
			background: #000;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			-webkit-filter: blur(50px);
			filter: blur(50px);
		}
	}
}
.swiper-cube {
	.swiper-slide-next + .swiper-slide {
		pointer-events: auto;
		visibility: visible;
	}
}
/* Cube slide shadows start */
.swiper-cube {
	.swiper-slide-shadow-cube.swiper-slide-shadow-top,
	.swiper-slide-shadow-cube.swiper-slide-shadow-bottom,
	.swiper-slide-shadow-cube.swiper-slide-shadow-left,
	.swiper-slide-shadow-cube.swiper-slide-shadow-right {
		z-index: 0;
		backface-visibility: hidden;
	}
}
/* Cube slide shadows end */
.swiper-fade {
	&.swiper-free-mode {
		.swiper-slide {
			transition-timing-function: ease-out;
		}
	}
	.swiper-slide {
		pointer-events: none;
		transition-property: opacity;
		.swiper-slide {
			pointer-events: none;
		}
	}
	.swiper-slide-active {
		&,
		& .swiper-slide-active {
			pointer-events: auto;
		}
	}
}
.swiper-flip {
	overflow: visible;
	.swiper-slide {
		pointer-events: none;
		backface-visibility: hidden;
		z-index: 1;
		.swiper-slide {
			pointer-events: none;
		}
	}
	.swiper-slide-active {
		&,
		& .swiper-slide-active {
			pointer-events: auto;
		}
	}
}
/* Flip slide shadows start */
.swiper-flip {
	.swiper-slide-shadow-flip.swiper-slide-shadow-top,
	.swiper-slide-shadow-flip.swiper-slide-shadow-bottom,
	.swiper-slide-shadow-flip.swiper-slide-shadow-left,
	.swiper-slide-shadow-flip.swiper-slide-shadow-right {
		z-index: 0;
		backface-visibility: hidden;
	}
}
/* Flip slide shadows end */
.swiper-free-mode > .swiper-wrapper {
	transition-timing-function: ease-out;
	margin: 0 auto;
}
.swiper-grid > .swiper-wrapper {
	flex-wrap: wrap;
}
.swiper-grid-column > .swiper-wrapper {
	flex-wrap: wrap;
	flex-direction: column;
}
.swiper-button-prev,
.swiper-button-next {
	// position: absolute;
	// top: var(--swiper-navigation-top-offset, 50%);
	// width: calc(var(--swiper-navigation-size) / 44 * 27);
	// height: var(--swiper-navigation-size);
	//margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
	z-index: 10;
	@include media-breakpoint-up(xl) {
		position: absolute;
		left: -2.5rem;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
	}
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: tint($orange, 55%);
	color: shade($orange, 50%);
	border-radius: 25rem;
	height: 60px;
	width: 60px;
	font-size: 1.5rem;
	@include transition(0.25);
	&.swiper-button-disabled {
		opacity: 0.35;
		cursor: auto;
		pointer-events: none;
	}
	&.swiper-button-hidden {
		opacity: 0;
		cursor: auto;
		pointer-events: none;
	}
	.swiper-navigation-disabled & {
		display: none !important;
	}
	svg {
		width: 100%;
		height: 100%;
		object-fit: contain;
		transform-origin: center;
	}
	.swiper-rtl & svg {
		transform: rotate(180deg);
	}
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
	left: -2.5rem;
	right: auto;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
	left: auto;
	right: -2.5rem;
}
.swiper-button-lock {
	display: none;
}
/* Navigation font start */
.swiper-button-prev,
.swiper-button-next {
	background-color: transparent;
	margin: 0 0.5rem;
	&:after {
		content: " " !important;
		/*font-family: "Line Awesome Free";
		font-weight: 900;
		font-size: var(--swiper-navigation-size);
		text-transform: none !important;
		letter-spacing: 0;
		font-variant: initial;
		line-height: 1;
		*/
		border-radius: 50%;
		height: 50px;
		width: 50px;
		border: 1px solid $grey-300;
		background: url('data:image/svg+xml,<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.9248 1C13.9248 4.63719 17.1281 8.07506 20.9999 8.07506M20.9999 8.07506C17.1281 8.07506 13.9248 11.5129 13.9248 15.1501M20.9999 8.07506L1 8.07507" stroke="%239C9C9C" stroke-width="1.5" stroke-linecap="square"/></svg>')
			no-repeat center center;
		transform: scaleX(1);
		@include transition(0.25);
	}
	&:hover {
		&:after {
			border: 1px solid $grey-500;
			background: $grey-600
				url('data:image/svg+xml,<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.9248 1C13.9248 4.63719 17.1281 8.07506 20.9999 8.07506M20.9999 8.07506C17.1281 8.07506 13.9248 11.5129 13.9248 15.1501M20.9999 8.07506L1 8.07507" stroke="%239C9C9C" stroke-width="1.5" stroke-linecap="square"/></svg>')
				no-repeat center center;
		}
	}
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
	&:after {
		content: " " !important;
		background: url('data:image/svg+xml,<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.9248 1C13.9248 4.63719 17.1281 8.07506 20.9999 8.07506M20.9999 8.07506C17.1281 8.07506 13.9248 11.5129 13.9248 15.1501M20.9999 8.07506L1 8.07507" stroke="%239C9C9C" stroke-width="1.5" stroke-linecap="square"/></svg>')
			no-repeat center center;
		transform: scaleX(-1);
	}
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
	&:after {
		content: "\f061";
	}
	left: auto;
	right: -2.5rem;
}
/* Navigation font end */
.swiper-pagination {
	position: absolute;
	text-align: center;
	transition: 300ms opacity;
	transform: translate3d(0, 0, 0);
	z-index: 10;
	&.swiper-pagination-hidden {
		opacity: 0;
	}
	.swiper-pagination-disabled > &,
	&.swiper-pagination-disabled {
		display: none !important;
	}
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
	bottom: var(--swiper-pagination-bottom, 8px);
	top: var(--swiper-pagination-top, auto);
	left: 0;
	width: 100%;
}
/* Bullets */
.swiper-pagination-bullets-dynamic {
	overflow: hidden;
	font-size: 0;
	.swiper-pagination-bullet {
		transform: scale(0.33);
		position: relative;
	}
	.swiper-pagination-bullet-active {
		transform: scale(1);
	}
	.swiper-pagination-bullet-active-main {
		transform: scale(1);
	}
	.swiper-pagination-bullet-active-prev {
		transform: scale(0.66);
	}
	.swiper-pagination-bullet-active-prev-prev {
		transform: scale(0.33);
	}
	.swiper-pagination-bullet-active-next {
		transform: scale(0.66);
	}
	.swiper-pagination-bullet-active-next-next {
		transform: scale(0.33);
	}
}
.swiper-pagination-bullet {
	width: var(
		--swiper-pagination-bullet-width,
		var(--swiper-pagination-bullet-size, 8px)
	);
	height: var(
		--swiper-pagination-bullet-height,
		var(--swiper-pagination-bullet-size, 8px)
	);
	display: inline-block;
	border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
	background: var(--swiper-pagination-bullet-inactive-color, #000);
	opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
	@at-root button#{&} {
		border: none;
		margin: 0;
		padding: 0;
		box-shadow: none;
		appearance: none;
	}
	.swiper-pagination-clickable & {
		cursor: pointer;
	}

	&:only-child {
		display: none !important;
	}
}
.swiper-pagination-bullet-active {
	opacity: var(--swiper-pagination-bullet-opacity, 1);
	background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
	right: var(--swiper-pagination-right, 8px);
	left: var(--swiper-pagination-left, auto);
	top: 50%;
	transform: translate3d(0px, -50%, 0);
	.swiper-pagination-bullet {
		margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
		display: block;
	}
	&.swiper-pagination-bullets-dynamic {
		top: 50%;
		transform: translateY(-50%);
		width: 8px;
		.swiper-pagination-bullet {
			display: inline-block;
			transition: 200ms transform, 200ms top;
		}
	}
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-horizontal.swiper-pagination-bullets {
	.swiper-pagination-bullet {
		margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
	}
	&.swiper-pagination-bullets-dynamic {
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;
		.swiper-pagination-bullet {
			transition: 200ms transform, 200ms left;
		}
	}
}
.swiper-horizontal.swiper-rtl
	> .swiper-pagination-bullets-dynamic
	.swiper-pagination-bullet {
	transition: 200ms transform, 200ms right;
}
/* Fraction */
.swiper-pagination-fraction {
	color: var(--swiper-pagination-fraction-color, inherit);
}
/* Progress */
.swiper-pagination-progressbar {
	background: var(--swiper-pagination-progressbar-bg-color, rgba(0, 0, 0, 0.25));
	position: absolute;
	.swiper-pagination-progressbar-fill {
		background: var(--swiper-pagination-color, var(--swiper-theme-color));
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		transform: scale(0);
		transform-origin: left top;
	}
	.swiper-rtl & .swiper-pagination-progressbar-fill {
		transform-origin: right top;
	}
	.swiper-horizontal > &,
	&.swiper-pagination-horizontal,
	.swiper-vertical > &.swiper-pagination-progressbar-opposite,
	&.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
		width: 100%;
		height: var(--swiper-pagination-progressbar-size, 4px);
		left: 0;
		top: 0;
	}
	.swiper-vertical > &,
	&.swiper-pagination-vertical,
	.swiper-horizontal > &.swiper-pagination-progressbar-opposite,
	&.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite {
		width: var(--swiper-pagination-progressbar-size, 4px);
		height: 100%;
		left: 0;
		top: 0;
	}
}
.swiper-pagination-lock {
	display: none;
}

.swiper-scrollbar {
	border-radius: var(--swiper-scrollbar-border-radius, 10px);
	position: relative;
	-ms-touch-action: none;
	touch-action: none;
	background: var(--swiper-scrollbar-bg-color, rgba(0, 0, 0, 0.1));
	.swiper-scrollbar-disabled > &,
	&.swiper-scrollbar-disabled {
		display: none !important;
	}
	.swiper-horizontal > &,
	&.swiper-scrollbar-horizontal {
		position: absolute;
		left: var(--swiper-scrollbar-sides-offset, 1%);
		bottom: var(--swiper-scrollbar-bottom, 4px);
		top: var(--swiper-scrollbar-top, auto);
		z-index: 50;
		height: var(--swiper-scrollbar-size, 4px);
		width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
	}
	.swiper-vertical > &,
	&.swiper-scrollbar-vertical {
		position: absolute;
		left: var(--swiper-scrollbar-left, auto);
		right: var(--swiper-scrollbar-right, 4px);
		top: var(--swiper-scrollbar-sides-offset, 1%);
		z-index: 50;
		width: var(--swiper-scrollbar-size, 4px);
		height: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
	}
}
.swiper-scrollbar-drag {
	height: 100%;
	width: 100%;
	position: relative;
	background: var(--swiper-scrollbar-drag-bg-color, rgba(0, 0, 0, 0.5));
	border-radius: var(--swiper-scrollbar-border-radius, 10px);
	left: 0;
	top: 0;
}
.swiper-scrollbar-cursor-drag {
	cursor: move;
}
.swiper-scrollbar-lock {
	display: none;
}
.swiper-thumbs {
	.swiper-slide {
		opacity: 0.35;
		@include flex-item(column, center, center);
		@include transition(0.3);
		transform: scale(0.85) !important;
		cursor: pointer;
		.clientsThumb {
			border: 5px solid $white;
			box-shadow: 0px 0 10px 0 rgba(0, 0, 0, 0.075);
			width: 100px;
			height: 100px;
			img {
				max-width: 120px;
			}
		}
	}
	.swiper-slide-thumb-active {
		opacity: 1;
		transform: scale(1) !important;
	}
}
.swiper-virtual .swiper-slide {
	-webkit-backface-visibility: hidden;
	transform: translateZ(0);
}
.swiper-virtual.swiper-css-mode {
	.swiper-wrapper::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		pointer-events: none;
	}
}
.swiper-virtual.swiper-css-mode.swiper-horizontal {
	.swiper-wrapper::after {
		height: 1px;
		width: var(--swiper-virtual-size);
	}
}

.swiper-virtual.swiper-css-mode.swiper-vertical {
	.swiper-wrapper::after {
		width: 1px;
		height: var(--swiper-virtual-size);
	}
}
.swiper-zoom-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	> img,
	> svg,
	> canvas {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}
}

.swiper-slide-zoomed {
	cursor: move;
	touch-action: none;
}
.slider-nav-bottom {
	position: relative;
	@include flex-item(row, center, center);
	.swiper-button-prev,
	.swiper-button-next {
		position: static;
		// left: auto;
		// top: auto;
		// bottom: 0;
		// right: 5rem;
		// transform: translate(0);
		transform: scaleX(1);
	}
	.swiper-button-next {
		transform: scaleX(1);
	}
}
