.statistics-wrapper {
	position: relative;
	overflow: hidden;
	z-index: 2;
	background: $black;
}
.statistics-card {
	background-color: transparent;
	border: 0;
	text-align: center;
	.statistics-num {
		color: $white;
		font-size: $text-4xl;
		font-weight: 800;
	}
	.statistics-title {
		color: $grey-100;
		margin-top: 2rem;
		font-size: $text-lg;
		font-weight: 400;
	}
}
