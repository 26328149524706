.footer {
	font-size: $text-base;
	background-color: shade($secondary, 60%);
	position: relative;
	z-index: 2;
	&::before {
		content: "";
		//	background: url("#{$imgBackground}footer-bg.png") no-repeat 0 0 / cover;
		height: 100%;
		width: 100%;
		position: absolute;
		right: 0;
		top: 0;
		opacity: 0.15;
		z-index: -1;
	}
	a {
		text-decoration: none;
		color: $white;
	}
}
.footer-top {
	position: relative;
	z-index: 2;
	overflow: hidden;
}
.footer-menu-title,
.footer-widgets-title {
	font-weight: 400;
	font-size: $text-md;
	margin-bottom: 1rem;
	color: $white;
	letter-spacing: 0.05rem;
}
.footer-menu {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		margin-bottom: 0.75rem;
		a {
			color: $grey-300;
			&:hover {
				color: $grey-100;
			}
		}
	}
}
.footer-bottom {
	p {
		color: $grey-100;
		font-size:$text-base;
		margin-bottom: 0;
	}
}
.footer-com-info, .glow-seperater{
	position: relative;
	&:after{
		content: "";
		position: absolute;
		bottom: -1rem;
		width: 80%;
		height: 1px;
		border-radius: 50%;
		left: 50%;
		transform: translateX(-50%);
		background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,.5) 50%,rgba(255,255,255,0) 100%);
		opacity: .25;
	}
}
.dot-seperater{
	li{
		position: relative;
		&:not(:last-child){
			&:after{
				content: "";
				position: absolute;
				top: 50%;
				width: .25rem;
				height: .25rem;
				border-radius: 50%;
				background-color: $gray-300;
				transform: translateY(-50%);
				right: -0.85rem;
			}
		}
	}
}

.grecaptcha-badge {
	z-index: 3;
}