$text-xs: 0.75rem;
$text-sm: 0.875rem;
$text-base: unquote("clamp(0.875rem, 0.7188rem + 0.5vw, 1rem)");
$text-md: unquote("clamp(1rem, 0.5625rem + 1vw, 1.125rem)");
$text-lg: unquote("clamp(1.05rem, -0.1034rem + 2.406vw, 1.375rem)");
$text-xl: unquote("clamp(1.25rem, -0.1917rem + 3.0075vw, 1.563rem)");
$text-2xl: unquote("clamp(1.5rem, -0.6626rem + 4.5113vw, 1.875rem)");
$text-3xl: unquote("clamp(1.875rem, -1.7293rem + 7.5188vw, 2.5rem)");
$text-4xl: unquote("clamp(2rem, -4.4878rem + 13.5338vw, 3.125rem)");
$text-5xl: unquote("clamp(3rem, -1.3252rem + 9.0226vw, 4.375rem)");
$text-6xl: unquote("clamp(4rem, 0.15rem + 7vw, 4.75rem)");

$font-family-sans-serif: "Syne", Roboto, "Helvetica Neue", "Noto Sans",
	"Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
	"Segoe UI Symbol", "Noto Color Emoji";
$font-fancy: "Syne";
$fontSize: (
	"xs": $text-xs,
	"sm": $text-sm,
	"base": $text-base,
	"md": $text-md,
	"lg": $text-lg,
	"xl": $text-xl,
	"2xl": $text-2xl,
	"3xl": $text-3xl,
	"4xl": $text-4xl,
	"5xl": $text-5xl,
	"6xl": $text-6xl,
);

@each $name, $size in $fontSize {
	.text-#{$name} {
		font-size: $size !important;
	}
}
$greyColors: (
	"grey-100": $grey-100,
	"grey-200": $grey-200,
	"grey-300": $grey-300,
	"grey-400": $grey-400,
	"grey-500": $grey-500,
	"grey-600": $grey-600,
	"grey-700": $grey-700,
	"grey-800": $grey-800,
	"grey-900": $grey-900,
);
@each $name, $color in $greyColors {
	.text-#{$name} {
		color: $color !important;
	}
}
body {
	font-family: $font-family-sans-serif;
	font-size: $text-lg;
}
p {
	font-size: unquote("clamp(1rem, -0.1034rem + 2.406vw, 1.125rem)");
}
.font-fancy {
	font-family: $font-fancy;
}
a {
	text-decoration: none;
}
a.link-underline {
	display: inline-block;
	position: relative;
	z-index: 2;
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 10%;
		background-color: $primary;
		transform: scale3d(0, 1, 1);
		transform-origin: 0% 50%;
		transition: transform 0.5s;
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	}
	&:hover:before {
		transform: scale3d(1, 1, 1);
	}
}
#target {
	stroke-width: 60px;
	fill: none;
	stroke: yellow;
	stroke-linecap: round;
}
