.service-card {
	display: block;
	position: relative;
	z-index: 2;
	.service-image {
		position: relative;
		img {
			max-height: 200px;
			min-height: 200px;
			object-fit: cover;
			width: 100%;
		}
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background: linear-gradient(
				to bottom,
				rgba($grey-900, 0) 0%,
				rgba($grey-900, 0.75) 100%
			);
			opacity: 0.25;
			@include transition(0.25);
		}
		&:after {
			content: "";
			position: absolute;
			width: 0;
			height: 100%;
			right: 0;
			top: 0;
			background: linear-gradient(
				to bottom,
				rgba($primary, 0.15) 0%,
				rgba($primary, 0.75) 100%
			);
			opacity: 0.25;
			@include transition(0.25);
		}
	}
	.service-title {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		color: $white;
		text-align: center;
	}
	&:hover {
		.service-image {
			&:after {
				width: 100%;
				opacity: 1;
				left: 0;
				right: auto;
			}
		}
	}
}
.serviceSlider-wrapper {
	.swiper-button-prev,
	.swiper-button-next {
		opacity: 0;
		visibility: hidden;
	}
	&:hover {
		.swiper-button-prev,
		.swiper-button-next {
			opacity: 1;
			visibility: visible;
		}
	}
}

.service-slide{
	border-radius: .75rem;
	overflow: hidden;
	margin-bottom: .25rem;
	height: auto;
	.service-module-card{
		height: 100%;
		+ .service-image{
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}
.service-slider-container{
	position: relative;
	.service-module-nav {
		@include flex-item(row,flex-start,center);
		position: absolute;
		right: 30px;
		top: 0;
		width: 100px;
		@include media-breakpoint-down(md){
			right: 30px;
			top: 0;
			width: 200px;
		}
	}
}
.service-module{
	.serviceImg{
		border-radius: 1rem;
		overflow: hidden;
		img{
			object-fit: cover;
			width: 100%;
			height: 100%
		}
	}
	.service-item{
		@include flex-item(row,flex-start,center);
		a{
			display: block;
			font-size: $text-md;
			color: $gray-400;
			background-color: rgba($white,.05);
			padding: 1rem;
			border-radius: .5rem;
			width: 100%;
			font-weight: 600;
			.service-item-icon{
				display: inline-block;
				margin-right: 1rem;
				width: 27px;
				height: 32px;
				svg {
					width: 100%;
					height: 100%;
				}
				svg,path, polygon{
					fill:$gray-600;
				}
			}
		}
	}
}
