body.mmactive {
	overflow: hidden;
}
.mobile_menu {
	display: none;
	padding: 0;
	position: relative;
}
.sm_menu_outer.active {
	.mobile_menu {
		display: block;
	}
	opacity: 1;
	z-index: 999;
}
.sm_menu_outer {
	height: calc(100vh - 60px);
	overflow-y: scroll;
	overflow-x: hidden;
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: -9;
	width: 100%;
	opacity: 0;
	background-color: $dark;
	-webkit-transition: opacity 0.2s;
	-moz-transition: opacity 0.2s;
	transition: opacity 0.2s;
	.mobile_menu {
		li {
			list-style-type: none;
			border-bottom: 0.5px solid rgba(0, 0, 0, 0);
			border-image: linear-gradient(
											to right,
											rgba(0, 0, 0, 0) 0%,
											#2a2a2a 20%,
											#2a2a2a 50%,
											#2a2a2a 80%,
											rgba(0, 0, 0, 0) 100%
			)
			0 0 1 0;
			a {
				color: $white;
				display: block;
				font-size: 1.125rem;
				font-weight: 400;
				padding: 1.25rem 1rem;
				text-decoration: none;
			}
			&.back {
				> a {
					color: $gray-400;
					text-transform: uppercase;
					font-weight: 700;
					font-size: 0.875rem;
					letter-spacing: 0.15rem;
					padding: 20px 5px 20px 55px;
					position: relative;
					&:before {
						content: "";
						position: absolute;
						top: 50%;
						left: 15px;
						height: 30px;
						width: 30px;
						opacity: 0.75;
						background: url("../../images/assets/arrow-right.svg") no-repeat 0 0 /100%;
						transform: translateY(-50%) scaleX(-1);
					}
				}
			}
		}
	}
	&.slide {
		.mobile_menu {
			.submenu {
				background-color: $dark;
				height: 100vh;
				position: absolute;
				right: -100%;
				top: 0;
				transition: all 0.5s ease 0s;
				width: 100%;
				z-index: 999;
				padding: 0;
			}
			li {
				&.active {
					> .submenu {
						right: 0;
					}
				}
				&.hasChild {
					> a {
						// background: url("../images/assets/arrow-right.svg") no-repeat scroll 95%
						// 	center / 10% auto;
						position: relative;
						&:before {
							content: "";
							position: absolute;
							top: 50%;
							right: 15px;
							height: 24px;
							width: 24px;
							opacity: 0.75;
							background: url("../../images/assets/arrow-right.svg") no-repeat 0 0 /100%;
							transform: translateY(-50%);
						}
					}
				}
			}
		}
	}
}

#sm_menu_ham {
	cursor: pointer;
	float: right;
	height: 25px;
	position: fixed;
	right: 5px;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: all 0.5s ease-in-out 0s;
	-moz-transition: all 0.5s ease-in-out 0s;
	-o-transition: all 0.5s ease-in-out 0s;
	transition: all 0.5s ease-in-out 0s;
	width: 30px;
	top: 15px;
	right: 15px;
	z-index: 9999;
	span {
		background-color: $primary;
		border-radius: 5px;
		display: block;
		height: 4px;
		left: 0;
		opacity: 1;
		position: absolute;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: all 0.25s ease-in-out 0s;
		-moz-transition: all 0.25s ease-in-out 0s;
		-o-transition: all 0.25s ease-in-out 0s;
		transition: all 0.25s ease-in-out 0s;
		width: 30px;
		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(2) {
			top: 10px;
			opacity: 0;
		}
		&:nth-child(3) {
			top: 10px;
			width: 20px;
		}
		&:nth-child(4) {
			top: 20px;
		}
	}
	&.open {
		span {
			&:nth-child(1) {
				left: 50%;
				top: 18px;
				width: 0;
			}
			&:nth-child(2) {
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				transform: rotate(45deg);
				opacity: 1;
			}
			&:nth-child(3) {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				transform: rotate(-45deg);
				width: 100%;
			}
			&:nth-child(4) {
				left: 50%;
				top: 18px;
				width: 0;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		display: none;

		.sm_menu_outer {
			display: none;
		}
	}
}

.sm_menu_outer.accordion {
	.mobile_menu {
		.submenu {
			display: none;
			padding: 0;
		}
		li.hasChild {
			ul.submenu {
				display: none;
				padding: 0;
			}
			> a {
				position: relative;
				&::before {
					position: absolute;
					content: "";
					background: url("../../images/assets/arrow-right.svg") no-repeat 0 0 /100%;
					right: 0;
					top: 20px;
					width: 24px;
					height: 24px;
					transition: all 0.19s ease;
				}
			}
		}
		.hasChild {
			> .submenu {
				li {
					padding-left: 20px;
				}
			}
		}
		li.active {
			> .submenu {
				right: auto;
			}
		}
		li.hasChild.active {
			> a {
				&::before {
					transform: rotate(89deg);
					transition: all 0.19s ease;
				}
			}
		}
	}
	li {
		display: block;
	}
}
