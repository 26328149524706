// scss-docs-start theme-color-variables
$primary: #246bfd;
$secondary: #183e69;
$success: #1ebb9d;
$info: #146aff;
$orange: #fb8f67;
$warning: #eab549;
$danger: #ff5151;
$light: #e6e8ea;
$dark: #0a0b0d;

// $grey-50: #f8fafc;
// $grey-100: #f1f5f9;
// $grey-200: #e2e8f0;
// $grey-300: #cbd5e1;
// $grey-400: #94a3b8;
// $grey-500: #64748b;
// $grey-600: #475569;
// $grey-700: #334155;
// $grey-800: #1e293b;
// $grey-900: #0f172a;

$grey-50: #e8e8e9;
$grey-100: #b7b8ba;
$grey-200: #959698;
$grey-300: #64666a;
$grey-400: #46494d;
$grey-500: #6b7280;
$grey-600: #16191d;
$grey-700: #111317;
$grey-800: #0d0f12;
$grey-900: #0a0b0d;

$body-color: $grey-200;
$black: #000000;
$white: #ffffff;
$imgBackground: "../../images/background/";
//$min-contrast-ratio: 2.25;

// $allColors: (
// 	"primary": $primary,
// 	"secondary": $secondary,
// 	"success": $success,
// 	"orange": $orange,
// 	"warning": $warning,
// 	"danger": $danger,
// 	"info": $info,
// 	"light": $light,
// );

//BUTTONS

$btn-font-weight: 600;
$dropdown-item-padding-y: 0.5rem;
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1.25rem;
$btn-padding-x: 1.75rem;
$btn-font-size-lg: 1.125rem;
$btn-padding-x-lg: 3rem;
$btn-padding-y-lg: 0.75rem;
$btn-border-radius: 0.3125rem;

$input-btn-padding-y-sm: 0.75rem;
$input-btn-padding-x-sm: 0.75rem;

//$btn-border-radius-lg: 0.25rem;

$headings-font-weight: 700;
$headings-color: $dark;

$enable-negative-margins: true;

$pagination-color: var(--bs-white) !default;
$pagination-bg: transparent !default;
$pagination-border-color: var(--bs-gray-dark) !default;

//$pagination-disabled-color:         var(--#{$prefix}secondary-color) !default;
$pagination-disabled-bg:            var(--bs-gray-dark) !default;
$pagination-disabled-border-color:  var(--bs-gray-dark) !default;

$pagination-focus-color:            $white !default;
$pagination-focus-bg:               $primary !default;

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               $primary !default;
$pagination-hover-border-color:     $primary !default; // Todo in v6: remove this?
