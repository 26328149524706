.header {
	background-color: $grey-900;
	// position: absolute;
	// top: 0;
	// width: 100%;
	// left: 0;
	// z-index: 100;
	transition: all 0.25s ease-in-out 0s;

	.header-wrapper {
		border-radius: 0 0 1.25rem 1.25rem;
		background-color: $white;
		padding: 0;
	}
	&.header-fixed {
		background: #9854ff;
		background: -moz-linear-gradient(top, #9854ff 0%, #4529c7 100%);
		background: -webkit-linear-gradient(top, #9854ff 0%, #4529c7 100%);
		background: linear-gradient(to bottom, #9854ff 0%, #4529c7 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9854ff', endColorstr='#4529c7',GradientType=0 );

		.topbar {
			display: none !important;
		}
	}
}

@include media-breakpoint-up(md) {
	body {
		padding-top: 0 !important;
	}
}
