.form-dark {
	.form-control {
		background: $gray-900;
		border: 0;
		color: $white;
		&:not(:placeholder-shown),
		&:focus {
			~ label {
				color: $gray-600;
				&:after {
					background: transparent;
				}
			}
		}
		&:disabled {
			background-color: shade($gray-600, 50%);
		}
	}
	.is-invalid {
		border: 1px solid $red-400;
	}
	label {
		color: $white;
	}
	.text-label {
		label {
			color: $grey-200;
		}
	}
	.service-text-container {
		background-color: $gray-900;
		max-height: 150px;
		overflow-y: auto;
	}
}
textarea {
	&.form-control {
		height: calc(8rem + calc(var(--bs-border-width) * 2)) !important;
		min-height: calc(8rem + calc(var(--bs-border-width) * 2)) !important;
	}
}
